import {
    useFloating,
    useInteractions,
    useClick,
    useRole,
    useDismiss,
    useListNavigation,
    autoUpdate,
    offset,
    flip,
    size,
} from '@floating-ui/react';
import { useState, useRef, useLayoutEffect, useCallback } from 'react';
import type { SelectOption } from '../types';
import useEffectWithoutMount from '../../../../../core/util/useEffectWithoutMount';
import useDropdownAnimation from './useDropdownAnimation';

const useFloatingSelect = ({
    options,
    preventCloseOnSelect,
    disabled,
    onChange,
    onMountChange,
}: {
    /**
     * Options for the select input
     */
    options: SelectOption[];
    /**
     * Prevent auto-closing dropdown when an option
     * is selected.
     */
    preventCloseOnSelect?: boolean;
    /**
     * The disabled state
     */
    disabled?: boolean;
    onChange(newValue: string): void;

    onMountChange(value: boolean): void;
}) => {
    const [open, setOpen] = useState(false);
    const [pointer, setPointer] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const listElementsRef = useRef<(HTMLElement | null)[]>(
        new Array(options.length + 1).fill(null)
    );

    const { x, y, strategy, refs, context } = useFloating<HTMLDivElement>({
        open,
        onOpenChange: setOpen,

        whileElementsMounted: autoUpdate,
        placement: 'bottom-start',
        middleware: [
            offset(8),
            flip(),
            size({
                apply({ rects, availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                        maxHeight: `${availableHeight}px`,
                    });
                },
                padding: 8,
            }),
        ],
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([
            useClick(context, { enabled: !disabled }),
            useRole(context, { role: 'listbox' }),
            useDismiss(context),
            useListNavigation(context, {
                listRef: listElementsRef,
                activeIndex,
                selectedIndex,
                onNavigate: setActiveIndex,
            }),
        ]);

    useLayoutEffect(() => {
        if (open && activeIndex != null && !pointer) {
            requestAnimationFrame(() => {
                listElementsRef.current[activeIndex]?.scrollIntoView({
                    block: 'nearest',
                });
            });
        }
    }, [open, activeIndex, pointer]);

    const handleSelect = useCallback(
        (changedIndex?: number) => {
            const index = changedIndex ?? activeIndex;
            if (index !== null) {
                setSelectedIndex(index);

                if (!preventCloseOnSelect) {
                    setOpen(false);
                }

                onChange(options[index - 1].value);

                requestAnimationFrame(() => {
                    refs.domReference.current?.focus();
                });
            }
        },
        [
            activeIndex,
            onChange,
            options,
            preventCloseOnSelect,
            refs.domReference,
        ]
    );
    const { isMounted, styles } = useDropdownAnimation(context);

    useEffectWithoutMount(() => {
        onMountChange(isMounted);
    }, [isMounted]);

    return {
        open,
        refs,
        getReferenceProps,
        context,
        strategy,
        y,
        x,
        getFloatingProps,
        setPointer,
        activeIndex,
        handleSelect,
        setOpen,
        listElementsRef,
        getItemProps,
        setActiveIndex,
        isMounted,
        styles,
    };
};

export default useFloatingSelect;
