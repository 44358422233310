import { clsx } from 'clsx';
import { memo, useCallback } from 'react';
import { FloatingFocusManager } from '@floating-ui/react';
import type { ListChildComponentProps } from 'react-window';

import useSelectInput from './hooks/useSelectInput';
import SelectTrigger from './SelectTrigger';
import SelectDropdown from './SelectDropdown';
import type { MultiSelectProps } from './types';
import useFloatingSelect from './hooks/useFloatingSelect';
import styles from './Select.module.scss';
import MultiSelectTriggerContent from './MultiSelectTriggerContent';
import WindowRender from './WindowRender';
import MultiSelectOption from './MultiSelectOption';

/* eslint  max-lines-per-function: ['error', 300] */
const MultiSelect = ({
    id,
    value,
    header: HeaderElement,
    onChange,
    options,
    searchable = false,
    placeholder,
    selectedCountBadge = false,
    noSelectedLabels = false,
    deselectableLabels,
    allOptionsSelectedText,
    disabled = false,
    success = false,
    error = false,
    rounded = false,
    small = false,
    filled = false,
    theme,
    autoWidth,
    windowRender,
    clearable,
    ...ariaProps
}: MultiSelectProps) => {
    const { searchValue, setSearchValue, filteredOptions } =
        useSelectInput(options);

    const handleChange = useCallback(
        (newValue: string) => {
            const currentValues = new Set(value);

            if (currentValues.has(newValue)) {
                currentValues.delete(newValue);
            } else {
                currentValues.add(newValue);
            }

            onChange(Array.from(currentValues));
        },
        [onChange, value]
    );

    const {
        refs: { setReference: selectTriggerReference, setFloating },
        getReferenceProps,
        context,
        strategy,
        y,
        x,
        open,
        getFloatingProps,
        setPointer,
        activeIndex,
        handleSelect,
        setOpen,
        listElementsRef,
        getItemProps,
        setActiveIndex,
        styles: animationStyles,
        isMounted,
    } = useFloatingSelect({
        options: filteredOptions,
        onChange: handleChange,
        preventCloseOnSelect: true,
        disabled,
        onMountChange: mounted => {
            if (!mounted) {
                setSearchValue('');
            }
        },
    });

    const selectedOptions = options.filter(item => value.includes(item.value));

    return (
        <div
            className={clsx(styles.select, theme, {
                [styles.autoWidth]: autoWidth,
            })}
            {...ariaProps}
        >
            <SelectTrigger
                ref={selectTriggerReference}
                isOpen={open}
                value={value.join(', ')}
                small={small}
                deselectableLabels={deselectableLabels}
                disabled={disabled || options.length === 0}
                success={
                    success || (noSelectedLabels && selectedOptions.length > 0)
                }
                error={error}
                getReferenceProps={getReferenceProps}
                rounded={rounded}
                filled={selectedOptions.length > 0 && filled}
                onClear={
                    clearable
                        ? () => {
                              onChange([]);
                          }
                        : undefined
                }
            >
                <MultiSelectTriggerContent
                    allOptionsSelectedText={allOptionsSelectedText}
                    deselectableLabels={deselectableLabels}
                    handleChange={handleChange}
                    optionsCount={options.length}
                    placeholder={placeholder}
                    selectedOptions={selectedOptions}
                    selectedCountBadge={selectedCountBadge}
                    noSelectedLabels={noSelectedLabels}
                    filled={selectedOptions.length > 0 && filled}
                />
            </SelectTrigger>
            {isMounted && (
                <FloatingFocusManager
                    modal={false}
                    returnFocus={false}
                    context={context}
                >
                    <div
                        className={styles.dropdownFloatingMenu}
                        ref={setFloating}
                        tabIndex={-1}
                        style={{
                            position: strategy,
                            top: y,
                            left: x,
                            overflow: 'auto',
                            ...animationStyles,
                        }}
                    >
                        <SelectDropdown
                            ref={listElementsRef}
                            searchable={searchable}
                            searchText={searchValue}
                            setSearchText={setSearchValue}
                            optionsCount={filteredOptions.length}
                            header={
                                HeaderElement ? (
                                    <HeaderElement options={filteredOptions} />
                                ) : undefined
                            }
                            context={context}
                            getFloatingProps={getFloatingProps}
                            setPointer={setPointer}
                            activeIndex={activeIndex}
                            handleSelect={handleSelect}
                            setOpen={setOpen}
                            setActiveIndex={setActiveIndex}
                        >
                            {windowRender ? (
                                <WindowRender
                                    filteredOptions={filteredOptions}
                                    windowRender={windowRender}
                                >
                                    {({
                                        index,
                                        style,
                                    }: ListChildComponentProps) => (
                                        <div
                                            style={style}
                                            key={filteredOptions[index].value}
                                        >
                                            <MultiSelectOption
                                                item={filteredOptions[index]}
                                                index={index}
                                                value={value}
                                                activeIndex={activeIndex}
                                                listElementsRef={
                                                    listElementsRef
                                                }
                                                getItemProps={getItemProps}
                                                handleSelect={handleSelect}
                                                id={id}
                                                handleChange={handleChange}
                                                window
                                            />
                                        </div>
                                    )}
                                </WindowRender>
                            ) : (
                                <>
                                    {filteredOptions.map((item, index) => (
                                        <MultiSelectOption
                                            key={item.value}
                                            item={item}
                                            index={index}
                                            value={value}
                                            activeIndex={activeIndex}
                                            listElementsRef={listElementsRef}
                                            getItemProps={getItemProps}
                                            handleSelect={handleSelect}
                                            id={id}
                                            handleChange={handleChange}
                                        />
                                    ))}
                                </>
                            )}
                        </SelectDropdown>
                    </div>
                </FloatingFocusManager>
            )}
        </div>
    );
};

export default memo(MultiSelect);
