import { useDeferredValue, useMemo, useState } from 'react';
import type { SelectOption } from '../types';

const useSelectInput = (options: SelectOption[]) => {
    const [searchValue, setSearchValue] = useState('');
    const deferredSearch = useDeferredValue(searchValue);

    const filteredOptions = useMemo(
        () =>
            options.filter(
                item =>
                    item.label
                        ?.toString()
                        .toLowerCase()
                        .includes(deferredSearch.toLowerCase()) ||
                    item.value
                        .toLowerCase()
                        .includes(deferredSearch.toLowerCase())
            ),
        [options, deferredSearch]
    );

    return {
        searchValue,
        setSearchValue,
        filteredOptions,
    };
};

export default useSelectInput;
