import { clsx } from 'clsx';
import { memo } from 'react';
import { getClickableAriaOptions } from '../../buttons';
import { TextBlock } from '../../typography';
import { Checkbox } from '../Checkbox';
import type { SelectOption } from './types';
import styles from './Select.module.scss';

/* eslint-disable jsdoc/require-jsdoc */
const MultiSelectOption = memo(
    ({
        item,
        value,
        activeIndex,
        index,
        listElementsRef,
        getItemProps,
        handleSelect,
        id,
        window,
        handleChange,
    }: {
        item: SelectOption;
        value: string[];
        activeIndex: number | null;
        index: number;
        listElementsRef: React.MutableRefObject<(HTMLElement | null)[]>;
        getItemProps: (
            userProps?: React.HTMLProps<HTMLElement> | undefined
        ) => Record<string, unknown>;
        handleSelect: (index?: number) => void;
        id: string;
        window?: boolean;
        handleChange: (newValue: string) => void;
    }) => {
        const props = window
            ? {
                  ...getClickableAriaOptions({
                      onPress: () => {
                          handleSelect(index + 1);
                      },
                      role: 'option',
                  }),
                  'aria-label': item.label?.toString(),
                  'aria-selected': value.includes(item.value),
              }
            : {
                  tabIndex: -1,
                  ...getItemProps({
                      onClick: () => {
                          handleSelect();
                      },
                  }),
                  role: 'option',
                  'aria-label': item.label?.toString(),
                  'aria-selected':
                      value.includes(item.value) || activeIndex === index + 1,
                  ref: (node: HTMLDivElement) => {
                      listElementsRef.current[index + 1] = node;
                  },
              };

        return (
            <div key={item.value} className={styles.optionItem} {...props}>
                <div
                    tabIndex={0}
                    className={clsx(
                        styles.dropdownMenuItem,
                        styles.contentHorizontalPadding,
                        {
                            [styles.active]: activeIndex === index + 1,
                            [styles.selected]: value.includes(item.value),
                        }
                    )}
                >
                    <Checkbox
                        id={`${id}-select-option-${item.value}`}
                        checked={value.includes(item.value)}
                        onChange={() => handleChange(item.value)}
                    >
                        <TextBlock primary>{item.label}</TextBlock>
                    </Checkbox>
                </div>
            </div>
        );
    }
);

export default MultiSelectOption;
