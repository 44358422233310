import { clsx } from 'clsx';
import type { ForwardedRef, ReactNode } from 'react';
import { memo, forwardRef } from 'react';
import BaseInput from '../text/BaseInput';
import { ChevronDownIcon, CloseIcon } from '../../icons';
import { HStack } from '../../layout';
import { Button } from '../../buttons';
import styles from './SelectTrigger.module.scss';

const SelectTrigger = forwardRef(
    (
        {
            isOpen,
            children,
            value,
            deselectableLabels,
            disabled = false,
            success = false,
            error = false,
            rounded = false,
            small = false,
            filled = false,
            getReferenceProps,
            onClear,
        }: {
            /**
             * Indicates current state of dropdown.
             */
            isOpen?: boolean;
            children: ReactNode;
            /**
             * Use small styles for the trigger.
             */
            small?: boolean;
            /**
             * Use filled styles for the trigger.
             */
            filled?: boolean;
            /**
             * Disable input
             */
            disabled?: boolean;
            /**
             * Should indicate whether input has error
             */
            error?: boolean;
            /**
             * Rounded trigger for input.
             */
            rounded?: boolean;
            /**
             * Should indicate whether input has success state
             */
            success?: boolean;
            /**
             * Trigger should have de-selectable labels.
             */
            deselectableLabels?: boolean;
            /**
             * Actual value for single select, all values joined for multi select.
             */
            value?: string;
            /**
             * Generates interaction props for the trigger.
             * This is provided by floating-ui
             */
            getReferenceProps(
                userProps?: React.HTMLProps<Element> | undefined
            ): Record<string, unknown>;
            /**
             * On clear callback
             */
            onClear?: () => void;
        },
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <div
                ref={ref}
                role="button"
                tabIndex={0}
                data-test-class="SelectTrigger"
                className={clsx(styles.trigger, {
                    [styles.dropdownOpen]: isOpen,
                    [styles.rounded]: rounded,
                    [styles.disableTransition]: deselectableLabels,
                    [styles.filled]: filled,
                    [styles.withDeselectableLabels]:
                        deselectableLabels && !!value,
                    [styles.clearable]: onClear && value,
                })}
                {...getReferenceProps()}
            >
                <BaseInput
                    value={isOpen || value ? 'filled' : undefined}
                    disabled={disabled}
                    error={error}
                    success={success}
                    small={small}
                    endAdornment={
                        <HStack gap="mini">
                            {onClear && value && (
                                <div className={styles.clearButton}>
                                    <Button
                                        text
                                        inline
                                        onClick={event => {
                                            event.stopPropagation();
                                            onClear();
                                        }}
                                    >
                                        <CloseIcon small bold />
                                    </Button>
                                </div>
                            )}
                            <div className={styles.triggerIcon}>
                                <ChevronDownIcon />
                            </div>
                        </HStack>
                    }
                    theme={filled ? styles.filledInputTheme : undefined}
                >
                    <div>
                        <div
                            className={clsx({
                                [styles.triggerPlaceholder]: !value && !rounded,
                                [styles.triggerText]: !!value,
                            })}
                        >
                            {children}
                        </div>
                    </div>
                </BaseInput>
            </div>
        );
    }
);

export default memo(SelectTrigger);
